/* PickupBiz Login Design V 1.0 - Designed by Zeeshan Gazi */
/*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}*/


.wave{
	position: fixed;
	bottom: 0;
	left: 0;
	height:100%;
	
	
}

.bodyDiv{
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
}



.container2{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
	margin:0 auto;

	

}

.img{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.login-content2{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}

.img img{
	width: 500px;
}

.form{
	width: 275px;
	
	
}

.login-content2 img{
    height: 100px;
}

.login-content2 h2{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.login-content2 .input-div{
	position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.login-content2 .input-div.one{
	margin-top: 0;
	width: 275px;
	height: 51px;
}

.i{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i{
	transition: .3s;
}

.input-div > div{
    position: relative;
	height: 45px;
}

.input-div > div > h5{
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 14px;
	transition: .3s;
}

.input-div:before, .input-div:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #393185;
	transition: .4s;
}

.input-div:before{
	right: 50%;
}

.input-div:after{
	left: 50%;
}

.input-div.focus:before, .input-div.focus:after{
	width: 50%;
}

.input-div.focus > div > h5{
	top: -5px;
	font-size: 15px;
}

.input-div.focus > .i > i{
	color: #393185;
}

.input-div > div > input{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-div.pass{
	margin-bottom: 4px;
	width: 275px;
	height: 51px;

}

.forgot a{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 15px;
	font-weight: bold;
	transition: .3s;
	padding-right: 20px;
	
}

a:hover{
	color: #17152b;
}

.btn{
	display: block;
	/*width: 100%;*/
	width: 275px;
	height: 51px;
	border-radius: 20px;
	outline: none;
	border: none;
	/*background-image: linear-gradient(to right, #393185, #2A2464);*/
	background-color: #393185;
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
	margin-top: 42px;
}
.btn:hover{
	/*background-position: right;*/
	background-color: #160f57;
}


@media screen and (max-width: 1050px){
	.container{
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px){
	form{
		width: 290px;
		margin-top:50px;
		
	}

	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}

	.img img{
		width: 400px;
	}
}

@media screen and (max-width: 900px){
	.container2{
		grid-template-columns: 1fr;
	}

	.img{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content2{
		justify-content: center;
	}
}


.logo{
	
	margin-bottom: 42px;
}

/*.hr{
	margin-top: 18px;
	height: 1.7px;
	width: 272px;
	background-color: #CBCBCB;
}*/

.h6Tag{
	font-size: 15px;
	margin-top: 12px;
	font-weight: bolder;
}

.h6Tag a{
	text-decoration: none;
}

.terms{
	font-size: 11px;
	margin-top: 110px;
}