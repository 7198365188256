html, body {
    margin: 0;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 1.5 vmin;
  }
  
  #page-numbers {
    list-style: none;
    display: flex;
  }
  
  #page-numbers > li {
    width: 30px;
    font-size: 15px;
    height: 30px;
    line-height: 30px;

  
    cursor: pointer;
    margin-top: 2vmin;
    margin-right: 1vmin;
    user-select: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: block;
    float: left;
    border-radius: 50%;
    color: #bad6f1;
    background-color:#3F51B5;
  }

  #page-numbers > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
  }
  
  #page-numbers > li:hover {
    color: white;
    background-color: #201085;
    width: 30px;
    font-size: 15px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
  }