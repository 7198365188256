* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  align-content: center;
}

html {
  background-image: linear-gradient(to right, #ddd, #eee);
  font-family: 'Montserrat';
  color: #222;
}
.inputDataEntryBalance {
  width: calc(10vmin * 5);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(1vmin * 5);
  color: #240C9A;
  margin-left: 1vmin;
}
.inputDataEntry {
  width: calc(10vmin * 5);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: calc(1.5vmin * 5);
  color: #240C9A;
}
.currencyDataEntry {
  width: calc(5vmin * 5);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: calc(1.5vmin * 5);
  color: #240C9A;
}
.container_hidefull {
  display: none;
}
.container {
  display: block;
}
.containerfull {
  width: calc(18vmin * 5);
  height: calc(28.5vmin * 6);
  margin: 0 auto;
  padding: 0;

}

.cardfull {
  width: calc(18vmin * 5);
  height: calc(28.5vmin * 5);
  
  margin: 0 auto;
  padding: 0;
  margin-top: 10vmin;
  position: relative;
  cursor: pointer; 
}

.card__frontfull {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: calc(2vmin * 5);
  box-shadow: 0 1vmin 2vmin rgba(0,0,0,.25);
}

.card__frontfull {
  background-color: #fff;
  transform: rotateY(0);
  backface-visibility: hidden;
  overflow: hidden;
}

.card__coverfull {
  width: calc(18vmin * 5);
  height: calc(10vmin * 5);
  background-color: #312DAC;
  background-size: cover;
  background-position: center;
}

.packagefull{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: calc(1.6vmin * 5);
    font-size: calc(1.6vmin * 5);
    color: #fff;
    font-weight: bold;
}

.card_profilefull {
  width: calc(11vmin * 5);
  height: calc(11vmin * 5);
  background-size: cover;
  border-radius: 50%;
  border: calc(0.4vmin * 5) solid #fff;
  position: absolute;
  top: calc(4.0vmin * 5);
  left: 50%;
  transform: translateX(-50%); 
}
.card_profilepicsPreview {
  width: calc(8vmin * 5);
  height: calc(8vmin * 5);
  background-size: cover;
  border-radius: 50%;
  border: calc(0.4vmin * 5) solid rgb(2, 3, 14);
  margin-left: 10vmin;
  transform: translateX(-50%); 
}

.card__detailsfull {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(5vmin * 5);
  text-align: center;
}

.user-namefull{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: calc(1.5vmin * 5);
    text-transform: uppercase;
    color: #240C9A;
    margin-bottom: calc(0.7vmin * 5);
}

.contact-nofull{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #707070;
    font-size: calc(1.4vmin * 5);
    margin-bottom: 0;
}
.balancefull{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #707070;
    font-weight: bold;
    font-size: calc(1.4vmin * 5);
    margin-bottom: 0;
}
.cityfull{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #707070;
    font-size: calc(1.4vmin * 5);
    margin-bottom: 0;
}
.expirefull{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #C55757;
    font-weight: bold;
    font-size: calc(1.4vmin * 5);
    margin-top: calc(0.5vmin * 5) ;
    margin-bottom: 0;
}