.profile-name{
    margin-top: 0px;
    margin-bottom:2px;
   font-weight: bold;
   margin-left: 10px;
}
.address{
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.avatar{
    margin-top: 5px;
}