.App {
  text-align: center;
}

.AppContainer{
  padding-top: 0;
}
.containerDataentryMain{
  padding: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;

}
.form-group {
  margin-bottom: 2vmin;
  font-family: "Roboto, Helvetica, Arial, sans-serif";
  margin: 0;
  font-size : 4vmin;
}
.containerDataentry{
  padding-left: 20px;
  padding-right: 20px;
}
.containerDataentryButton{
  padding-top: 10px;
}
.AppContainerMainPlaceholder{
  padding-left: 0px;
  padding-right: 0px;
}

.AppContainerMainPlaceholderCards{
  margin-left: 6vmin;
}

.pagination {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
}

.pagination a {
  color: black;
  float: left;
  text-decoration: none;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 50%;
}

.buttonAddNew{
  margin-left: 5vmin;
}

.logocss {
  height: 34px;
  width: 82px;
}

.pickupbizdashboard{
  margin-left: auto;
}

.pickupbizdashboardMain{
  padding-top: 1vmin;
  padding-bottom: 1vmin;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
